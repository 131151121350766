import {
    Header,
    BackgroundForClient,
    Icon1,
    Icon2,
    Icon3,
    Icon4,
    Icon5,
    Icon6,
    Left1,
    RightArrow1,
    Right1,
    LeftArrow1,
    RightArrow2,
    Left2,
    LeftArrow2,
    Right2,
    Illustration,
    Ico1,
    Ico2,
    Ico3,
    Ico4,
    Ico5,
    Ico6,
    Ico7,
    Illustration1,
    BackgroundBusiness,
    Solution,
    Check,
    Phone,
    City,
    forPhone,
} from "../../images/SingleCase/UtilityManger"

const dataForTwoCents = {
    type: "project",
    smart: true,
    classComponent: "TwoCents",
    nextLink: "cases/social-app/",
    prevLink: "cases/ingo/",
    data: {
        client: [
            {
                icon: Icon1,
                text: 'Water utility \nmanagement'
            },
            {
                icon: Icon2,
                text: 'Electric utility \nmanagement'
            },
            {
                icon: Icon3,
                text: 'Gas utility \nmanagement'
            },
            {
                icon: Icon4,
                text: 'Utility billing \nmanagement'
            },
            {
                icon: Icon5,
                text: 'Service \nmaintenance',
                subtext: '(cleaning of territories, maintenance of \nelevators, etc.)'
            },
            {
                icon: Icon6,
                text: 'Managing requests for \nspecialist services',
                subtext: '(electrician, plumber, \ncarpenter, etc.)'
            }
        ],
        business: [
            {
                icon: Ico1,
                text: 'Collection and processing \nmeter \nreadings (gas, water, etc.)'
            },
            {
                icon: Ico2,
                text: '“All-in-one personal account” \nfor consumers'
            },
            {
                icon: Ico3,
                text: 'Universal system \nfor different suppliers'
            },
            {
                icon: Ico4,
                text: 'Acceptance and \naccounting of payments'
            },
            {
                icon: Ico5,
                text: 'Accrual, formation \nand transfer of invoices',
            },
            {
                icon: Ico6,
                text: 'Processing requests \n(from tenants, consumers, homeowners)',
            },
            {
                icon: Ico7,
                text: 'Analytics, statistics and reports on the company\'s activities',
            }
        ],
        solution: [
            {
                number: '01',
                headerText: 'Developed a Mobile Application',
                text: 'For homeowners/consumers (subscribers of a utility company)'
            },
            {
                number: '02',
                headerText: 'Developed Cloud Web Interface ',
                text: 'Service for operators, administrators, supervisors of the management company and supplier companies'
            },
            {
                number: '03',
                headerText: 'Used mathematical methods and AI',
                text: 'To optimize debts'
            },
        ],
        benefits: [
            {
                headerText: '9 out of 10 homeowners pay utility bills on time',
                text: 'On the same or the next day established by the requirements of the communal service.'
            },
            {
                headerText: 'The process of billing for utilities has been reduced to a few minutes:',
                text: '1.The consumer received a notification → Photographed the meter → The payment was auto-billed.',
                text1: '2.The consumer entered the personal account → Checked his balance → Made payments.',
            },
            {
                headerText: 'Increase in profit and customer loyalty by connecting additional services:',
                text: 'The subscriber reports the problem in the application → The system sends a notification to the contractor → The master provides the service and pays the commission.'
            },
        ],
        getEmail: {
            first: {
                title: 'Are you familiar with these issues?',
                text: 'Tell us about your troubles and difficulties and get demo access to UMS \nUtilitium to make your business effective!',
                button: 'GET DEMO ACCESS'
            },
            second: {
                title: 'Looking for a similar solution for your utility company?',
                text: 'You can try our software for free! Leave your email to get access.',
                button: 'UPGRADE YOUR BUSINESS'
            },
            third: {
                title: 'Looking for utilities management software?',
                text: 'Leave us your email to learn about the features of our all-in-one utility software.',
                button: 'GET FULL PRESENTATION'
            },
        },
        resultPossible: [
            {
                icon: Check,
                text: 'Connections of the auto-reminder function about the need to transfer meter readings. (the subscriber receives a notification that he needs to take a photo of the accounting device)'
            },
            {
                icon: Check,
                text: 'Processing of photos with readings of metering devices by artificial intelligence and automatic invoicing. (the operator does not need to manually process the data and issue invoices)'
            },
            {
                icon: Check,
                text: 'Connections of popular payment systems and convenient payment methods: both automatic debiting and manual payment are possible. (intuitive payment process does not cause resistance from users)'
            },
            {
                icon: Check,
                text: 'Integration into the system of third-party contractors and a streamlined system for managing customer requests of the utility company.'
            },
        ],
        images: {
            Left1,
            RightArrow1,
            Right1,
            LeftArrow1,
            RightArrow2,
            Left2,
            LeftArrow2,
            Right2,
            Illustration,
            Illustration1,
            BackgroundBusiness,
            Solution,
            City,
            Phone,
            forPhone,
            backgroundForClient: BackgroundForClient,
            header: Header,
        },
    },
    relatedProjects: [
        {
            link: "/cases/karpatska-chaika",
            id: "seagull",
            title: "Carpathian Seagull",
        },
        {
            link: "/cases/crm-sap-integration",
            id: "crmSap",
            title: "CRM/SAP Integration",
        },
        {
            link: "/cases/gud-job",
            id: "gud-job",
            title: "Gud Job",
        },
        {
            link: "/cases/ilich-realty",
            id: "illich3",
            title: "Ilich Realty",
        },
    ],
    link: "/cases/2cents",
}

export default dataForTwoCents
