import React, { useState, useContext } from 'react'
import { useFormik } from 'formik'
import { hubSpotEstimateFormId, hubSpotCookieName } from '../../../js/config'
import ThankYouMessage from './thanckyou'
import Cookies from 'js-cookie'
import SnackbarContext from '../../../contexts/SnackbarContext'
import { sendEmails } from "../../../js/sendMail"

const Form = props => {
  const [thankYou, setThankYou] = useState(false)
  const [succesCheack, setSuccesCheack] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)

  const validate = values => {
    if (isWaiting) {
      return false;
    }
    const errors = {}
    const checkEmail = email => {
      if (!email) {
        errors.email = 'Please enter your email'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'Please enter correct email'
      }
    }

    checkEmail(values.email)
    return errors
  }
  const isBrowser = typeof window !== 'undefined'
  const hutk = isBrowser ? Cookies.get(hubSpotCookieName) : null
  const pageUri = isBrowser ? window.location.href : null
  const pageName = isBrowser ? document.title : null
  const formik = useFormik({
    initialValues: {
      email: '',
      formSum: ''
    },
    validate,
    onSubmit: async (values) => {
      if (isWaiting) {
        return false;
      }
      values.form = 8;
      setIsWaiting(true);
      const submitCheck = await sendEmails(formik.values, formik.values.file, hubSpotEstimateFormId, {
        hutk: hutk,
        pageUri: pageUri,
        pageName: pageName
      })
      setIsWaiting(false);
      setSuccesCheack(submitCheck);
      setThankYou(true)
      setTimeout(() => {
        setThankYou(false)
        formik.resetForm()
      }, 5000)
    }
  })
  const context = useContext(SnackbarContext)

  const SubmitHandler = (event) => {
    event.preventDefault()
    formik.values.formSum = event.target.querySelector('input[name="formSum"]').value;
   
    const snackbarData = {
      message: 'No Internet Connection. Check your network and try again.',
      severity: 'warning',
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    }
    if (!window.navigator.onLine)
      return context.handleOpenSnackbar({ ...snackbarData })
    formik.handleSubmit()
  }
  return (
    <>
      {!thankYou ? (
        <form
          id='formId'
          onSubmit={(e) => SubmitHandler(e, formik.values, formik.errors)}
          >
          <input id="formSum" type="hidden" name="formSum" value={props.summary} />
          <div className={formik.errors.email ? 'errorDiv' : 'hidden'}>
            {formik.errors.email}
          </div>
          <input
            id='email'
            type='email'
            className={
              formik.errors.email ? 'error emailInput' : 'success emailInput'
            }
            placeholder='Enter your email'
            value={formik.values.email}
            disabled={isWaiting}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <input type='submit' className='myBtn' value={isWaiting ? 'Sending...' : props.button} />
        </form>
      ) : (
        <ThankYouMessage 
        succes={succesCheack}
        />
      )}
    </>
  )
}
export default Form
