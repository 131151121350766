import React from "react"

import succes from "../../../images/SingleCase/UtilityManger/succes.png"
import wrong from "../../../images/SingleCase/UtilityManger/wrong.png"
import "./utility.scss"

export default function ThankYouMessage(props) {
  
  return (
    <div className="thankyou-utilitium">
      <div className="elements">
        <div className="thankyou-header-utilitium">
          <img src={props.succes === false ? wrong : succes} />
        </div>
        <div className="sup-description-utilitium">
          <span className="sup-description-upper">
          {props.succes === false ? 'Something went wrong' :  'Thanks'}
          </span>
        </div>
        {props.succes === false ?
        <span className="utilitium-description">
        Please try to fill out the form later or call: <br />

        <a
          className="blue-text"
          target="_blank"
          href="tel://380952794212"
        >
          +38 (095) 27 94 212
        </a>
      </span>
        :
        <span className="utilitium-description">
          Your application has been successfully sent. We <br /> will contact you shortly.
          <br />Or call:&nbsp;
          <a
            className="blue-text"
            target="_blank"
            href="tel://380952794212"
          >
            +38 (095) 27 94 212
          </a>
        </span>
        }
      </div></div>
  )
}
