import React from 'react';
import Form from "./form"

const GetDemoAccess = (props) => {
    const endpointUrl = () => {
        let url = process.env.GATSBY_API_URL || "https://edelivery-dev.os-system.com"
        return url
    }
    const authHeader = () => {
        const d = new Date()
        const hash = crypto
            .createHash("md5")
            .update(d.toISOString().split("T")[0])
            .digest("hex")
        return {
            "X-Authorization": hash,
        }
    }
    return (
        <div className="blockGetdemo d-flex flex-column align-items-center">
            <div className="header_h2">
                {props.header}
            </div>
            <div className="subHeader_h5">
                {props.text}
            </div>
            <div className="sendGetDemo mb-100">
                <Form 
                summary={props.summary}
                title={props.header}
                button={props.button}
                />
            </div>
        </div>
    );
};
export default GetDemoAccess