import React from 'react'

import Slider from "react-slick"
import {
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  Slider6,
  Slider7,
  Slider8,
} from "../../../images/SingleCase/UtilityManger"
const settings = {
    dots: true,
    speed: 400,
    infinite: true,
    draggable: true,
    adaptiveHeight: false,
    arrows: false,
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: "ease-in-out",
    useTransform: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: false,
          centerMode: false,
  
        },
      },
      {
        breakpoint: 1300,
        settings: {
          arrows: false,
          centerMode: false,
          speed: 450,
          cssEase: "ease-out",
        },
      },
      {
        breakpoint: 1019,
        settings: {
          speed: 300,
          cssEase: "ease-in",
          useTransform: false,
          adaptiveHeight: false,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          speed: 300,
          // lazyLoad: true,
          cssEase: "ease-out",
          useTransform: true,
        },
      },
  
    ],
  }
const BenefitsObtaind = (props) => {
    const sliderImg = [
        Slider1,
        Slider2,
        Slider3,
        Slider4,
        Slider5,
        Slider6,
        Slider7,
        Slider8,
      ]
    return (
        <div className="container p-0">
            <img className="imgCity d-none d-sm-block" src={props.data.images.City} alt="Check" />
            <div className='header_h1 mt-148'>Benefits obtained</div>
            <div className="benefitsBlock">
                <div className="benefitsBlock__item-text">
                    {
                        props.data.benefits.map((data) => (
                            <div key={data.text} className="benefits">
                                <div className="header_h3">
                                    {data.headerText}
                                </div>
                                <div className="subHeader_h5">
                                    {data.text}<br />
                                    {data.text1 && <p> {data.text1} </p>}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="benefitsBlock__item-image">
                    <div className="block_images">
                      <div className="company-carousel-wrapper">
                        <div className="blockeds"></div>
                        <Slider {...settings}>
                          {sliderImg.map((item, i) => (
                            <img className="review-item" key={i} src={item} alt="Our team photo"/>
                          ))}
                        </Slider>
                      </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex align-items-lg-center mt-148">
                <div className='headerHome col-lg d-flex justify-content-start '>
                    <div className='header_h1'>How did such results become possible?</div>
                </div>
            </div>

            <div className="row mt-60 mb-148">
                <div className="col-lg">
                    <div className="textBlock">
                        {
                            props.data.resultPossible.map((item) => (
                                <div key={item.text} className="block">
                                    <img src={item.icon} alt="Check" />
                                    <div className="text subHeader_h5">{item.text}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BenefitsObtaind