import React from 'react';

const BussinessChallenge = (props) => {
    const checkIsWidth = (index) => {

        if (index >= 2 && index <= 4) {
            if (props.width <= 1200) {
                return "blockForBusiness"
            } else {
                return "col-lg-4"
            }
        } else {
            if (props.width <= 1200) {
                return index === 6 ? "blockForBusiness grid-area" : "blockForBusiness"
            } else {
                return "col-lg-6 d-flex businessBlock"
            }
        }
    }
    const changeTextForMobile = (text, index) => {
        let textChanged;
        if (index === 2) {
            textChanged = text.replace(/\r?\n/g, "");
            textChanged = textChanged.replace('system', ' \n system');
        } else if (index === 5) {

            textChanged = text.replace(/[\(]+\D+[)]/g, '(from tenants, consumers...)');

        } else {
            textChanged = text.replace(/[\(]+\D+[)]/g, '');
        }
        return textChanged;
    }
    return (
        <div className="row mt-161">
            <div className="ilustart"><img src={props.data.images.Illustration1} alt="Illustration" /></div>
            <div className="container">
                <img className="ilustartc" src={props.data.images.BackgroundBusiness} alt="Background" />
                <div className="row d-flex align-items-lg-center">
                    <div className='col-lg-4 col-md-6 col-sm-6 d-flex justify-content-start'>
                        <div className='header_h1'>Business challenge</div>
                    </div>
                    <div className='col-lg col-md col-sm pt-md-1 d-flex justify-content-start'>
                        <div className={props.width <= 576 ? 'subHeader_h5' : 'subHeader_h5 pl-4'}>
                            To create complex software for utility companies that will combine the solution{props.width > 1200 && <br />}
                            of the following tasks below.
                        </div>
                    </div>
                </div>
                <div className="row business mt-317">
                    {
                        props.data.business.map((data, index) => (
                            <div key={data.text} className={checkIsWidth(index)}>
                                <div className="business_block d-flex flex-column align-items-center zIndex2">
                                    <div className="header_images">
                                        <img src={data.icon} alt={data.text} />
                                    </div>
                                    <div className="subHeader_h5">{changeTextForMobile(data.text, index)}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
export default BussinessChallenge;