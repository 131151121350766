import React from 'react';

const Solution = (props) => {
    return (
        <div className="row mt-310">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className='col-lg-4 col-md-4 d-flex justify-content-start'>
                        <div className='header_h1'>Solution</div>
                    </div>
                    <div className='col-lg col-md d-flex justify-content-start'>
                        <div className={props.width <= 576 ? 'subHeader_h5' : 'subHeader_h5 pl-4'} >
                            To meet the challenges we used an approach that helped to simplify{props.width > 1200 && <br />}
                            and automate routine processes, this has increased the utility company’s{props.width > 1200 && <br />}
                            employees speed and convenience, and the loyalty level of utility consumers.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-5 blockSolution">
                        {
                            props.data.solution.map((data) => (
                                <div key={data.text} className="row solution">
                                    <div className="col-lg-1 col-md-1 col-1 number_h3">
                                        {data.number}
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-10">
                                        <div className="header_h3">
                                            {data.headerText}
                                        </div>
                                        <div className="subHeader_h5">
                                            {data.text}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-lg-6 col-md-6 d-none d-sm-flex">
                        <div className="block_images">
                            <img src={props.width <= 768 ? props.data.images.forPhone : props.data.images.Solution} alt="Solution" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Solution