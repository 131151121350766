import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import UtilityManager from "../../components/SingleCase/UtilityManagment"
// import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
// import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForUtility from "../../information/Cases/dataForUtility"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class CartoveraPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      // relatedProjects,
      data,
    } = dataForUtility

    return (
      <section className="singleProject">
        <SEO
          title="Utility Management Software - Development Case"
          canonical={`${mainUrl}/cases/utility-management-software/`}
          description={`A business case of developing software for utility companies: "How to create a comprehensive utility management solution that has reduced the time from receiving meter readings to paying the bill to a few seconds"`}
        />
        <Layout showFormPoint={100}>
          <UtilityManager data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
        </Layout>
      </section>
    )
  }
}
