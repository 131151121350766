import React, { useState, useEffect } from 'react'
import GetDemoAccess from './GetDemoAccess'
import BussinessChallenge from './BussinesChallenge'
import Solution from './Solution'
import BenefitsObtaind from './BenefitsObtained'
import Slider from './SliderFooter'
import './utility.scss'

const UtilityManagment = ({ data }) => {
    const componentMounts = () => {
        const [windowSize, setWindowSize] = useState(0);
        useEffect(() => {
            const handleResize = () => {
                setWindowSize(window.innerWidth);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }
    const isSize = componentMounts()
    return (
        <div className='ums-container'>
            <section className='first-section'>
                <div className='second-section-image'><div className='shadow'></div><img src={data.images.header} alt="header" /></div>
                <div className='container'>
                    <div className='header-wrapper'>
                        <div className='title-wrapper'>
                            <h1 className='utility-header'><span className='twocents-header-bluetext'>Utility Management <br />Software Development</span></h1>
                            <p>Utilitium is a ready-made and customizable solution for {isSize > 768 && <br />}management and utility companies that can be optimized
                            {isSize > 768 && <br />}to meet the individual needs and tasks of any organization.</p>
                        </div>
                    </div>
                </div>
            </section><img className="img-background" src={data.images.backgroundForClient} alt="Fon" />
            <section className='content-section'>
                <div className='container d-flex flex-column align-items-stretch'>
                    <div className='row d-flex align-items-center'>
                        <div className='col-lg-4 col-md-3 d-flex justify-content-start'>
                            <div className='header_h1'>Client</div>
                        </div>
                        <div className='col-lg col-md d-flex justify-content-start'>
                            <div className='subHeader_h5'>
                                An American utility management company that provides homeowners {isSize > 768 && <br />}
                                and tenants of a cottage community with a range of services for:
                            </div>
                        </div>
                    </div>
                    <div className="row managment">
                        {
                            data.client.map((block) => (
                                <div key={block.text} className="header_block col-md-lg-4 d-flex flex-column align-items-center">
                                    <div className="header_images">
                                        <img src={block.icon} alt={block.text} />
                                    </div>
                                    <div className="text_h3">{block.text}</div>
                                    {block.subtext && isSize >= 576 && <div className="subtext_h3">{block.subtext}</div>}
                                </div>
                            ))
                        }
                    </div>
                    <div className="row mt-310">
                        <div className={isSize <= 1200 ? 'col-lg-7 col-md-6 d-flex justify-content-start' : 'col-lg-6 d-flex justify-content-start'}>
                            <div className='header_h1 m-sm-2'>The problem
                                and its causes</div>
                        </div>
                        <div className='col-lg col-md d-flex justify-content-start align-items-center'>
                            <div className='subHeader_h5 pl-md-lg-4'>
                                Huge debts and cash gaps of the management<br />
                                company to utility providers for a variety of reasons:
                            </div>
                        </div>
                    </div>
                    <div className="row mt-60 mb-100 ">
                        <div className="Mall container">
                            <div className="blockTopText first">
                                To pay bills, consumers need to go to the bank or enter all the data in Internet banking on their own.
                            </div>
                            <div className="blockTopText second">
                                Scattered data from different suppliers, frequent cases of data duplication.
                            </div>
                            <div className="blockTopText third">
                                Homeowners don’t transmit meter readings on time.
                            </div>
                            <div className="blockTopText fourth">
                                Utility bills are often lost and not paid on time.
                            </div>
                        </div>
                        <div className="container p-0 LargeTime">
                            <div className="row">
                                <div className={isSize <= 1200 ? "col-5" : "col-6"}>
                                    <div className="blockTopLeft">
                                        <img src={data.images.Left1} alt="Left" />
                                        <div className="blockTopText">
                                            To pay bills, consumers need to go to the bank or enter all the data in Internet banking on their own.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="blockTopCenter">
                                        <img src={data.images.RightArrow1} alt="Right arrow" />
                                    </div>
                                </div>
                                <div className={isSize <= 1200 ? "col-6" : "col-5"}>
                                    <div className="blockTopRight d-flex justify-content-end">
                                        <img src={data.images.Right1} alt="Right" />
                                        <div className="blockTopText">
                                            Scattered data from different suppliers, frequent cases of data duplication.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-center mt-4">
                                    <div className="blockCenterLeft">
                                        <img src={data.images.LeftArrow1} alt="Left" />
                                    </div>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="illustration">
                                <img src={data.images.Illustration} alt="Illustration" />
                            </div>
                            <div className="row bottomForclock">
                                <div className="col"></div>
                                <div className="col-2">
                                    <div className="blockCenterRight">
                                        <img src={data.images.RightArrow2} alt="Right" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="blockBottomLeft">
                                        <img src={data.images.Left2} alt="Left" />
                                        <div className="blockTopText">
                                            Homeowners don’t transmit meter readings on time.
                                        </div>
                                    </div></div>
                                <div className="col-1">
                                    <div className="blockBottomCenter">
                                        <img src={data.images.LeftArrow2} alt="Left" />
                                    </div></div>
                                <div className="col-7 d-flex justify-content-end">
                                    <div className="blockBottomRight">
                                        <div className="blockTopText">
                                            Utility bills are often lost and not paid on time.
                                        </div>
                                        <img src={data.images.Right2} alt="Right" />
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <GetDemoAccess
                        summary="form#1"
                        header={data.getEmail.first.title}
                        text={data.getEmail.first.text}
                        button={data.getEmail.first.button}
                        width={isSize}
                    />
                </div>
                <div className='container d-flex flex-column align-items-stretch'>
                    <BussinessChallenge
                        width={isSize}
                        data={data}
                    />
                    <Solution
                        width={isSize}
                        data={data}
                    />
                </div>
                <div className="container-fluid p-0">
                    <GetDemoAccess
                        summary="form#2"
                        header={data.getEmail.second.title}
                        text={data.getEmail.second.text}
                        button={data.getEmail.second.button}
                        width={isSize}
                    />
                </div>
                <div className='container d-flex flex-column align-items-stretch'>
                    <BenefitsObtaind
                        data={data}
                        width={isSize}
                    />
                </div>
                <div className="container-fluid p-0">
                    <GetDemoAccess
                        summary="form#3"
                        header={data.getEmail.third.title}
                        text={data.getEmail.third.text}
                        button={isSize > 440 ? data.getEmail.third.button : data.getEmail.first.button}
                        width={isSize}
                    />
                </div>
            </section>
        </div>
    )
}

export default UtilityManagment